import { DeviceType } from "./@types/global";
import IMask from "imask";
import Menu from "./components/menu";
import Modal from "./components/modal";
import { domReady } from "@roots/sage/client";
import lazyLoadImages from "./components/lazyLoad";

const main = async (err): Promise<void> => {
  if (err) console.error(err);

  await onResize();
  window.addEventListener("resize", onResize);

  window.setTimeout(() => {
    new Menu();

    // const phoneInput = document.querySelector("input[name='your-phone']");
    // phoneInput &&
    //   IMask(phoneInput as HTMLElement, {
    //     mask: "+{48} 000 000 000",
    //     lazy: false,
    //     placeholderChar: "_",
    //   });

    const showMoreBtn = document.querySelector('a[href="#show-more"]');
    showMoreBtn &&
      showMoreBtn.addEventListener("click", (e) => {
        e.preventDefault();
        const showMoreContent = document.querySelector("#show-more");
        showMoreContent && showMoreContent.classList.toggle("hidden");
        showMoreBtn.textContent = showMoreBtn.textContent === "Rozwiń" ? "Schowaj" : "Rozwiń";
      });

    lazyLoadImages();

    const ReportModalEl = document.querySelector("#report-damage") as HTMLElement;
    ReportModalEl && new Modal(ReportModalEl, ".report-modal", ReportModalEl.querySelector(".close-modal") as HTMLElement).init();

    const PolicyModalEl = document.querySelector("#buy-policy") as HTMLElement;
    PolicyModalEl && new Modal(PolicyModalEl, ".buy-modal", PolicyModalEl.querySelector(".close-modal") as HTMLElement).init();

    // const RulesModalEl = document.querySelector("#rules-modal") as HTMLElement;
    // RulesModalEl && new Modal(RulesModalEl, ".rules-modal", RulesModalEl.querySelector(".close-modal") as HTMLElement).init();

    const PrivacyPolicyModalEl = document.querySelector("#privacy-policy-modal") as HTMLElement;
    PrivacyPolicyModalEl && new Modal(PrivacyPolicyModalEl, ".privacy-policy-modal", PrivacyPolicyModalEl.querySelector(".close-modal") as HTMLElement).init();

    const DownloadFilesModalEl = document.querySelector("#download-files-modal") as HTMLElement;
    DownloadFilesModalEl && new Modal(DownloadFilesModalEl, ".download-files-modal", DownloadFilesModalEl.querySelector(".close-modal") as HTMLElement).init();
  }, 1);

  //get anchor elements with href starting with #
  const anchors = document.querySelectorAll('a[href^="#"]');
  anchors.forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();

      const href = anchor.getAttribute("href");
      const target = document.querySelector<HTMLElement>(href as string);

      if (href === "#show-more" || href?.includes("modal")) return;

      const offsetTop = target?.offsetTop as number;

      window.scrollTo({
        top: offsetTop - 100,
        behavior: "smooth",
      });
    });
  });

  //add eventlistener to button with class close-cookies
  const closeCookiesBtn = document.querySelector(".close-cookies");

  const cookiesPopup = document.querySelector(".cookie-popup") as HTMLElement;

  //check if accept-cookies cookie is set

  if (cookiesPopup) {
    const acceptCookies = document.cookie.split(";").some((item) => item.trim().startsWith("accept-cookies="));

    if (acceptCookies) return;

    cookiesPopup?.classList.remove("hidden");

    closeCookiesBtn &&
      closeCookiesBtn.addEventListener("click", () => {
        const trackingCookies = document.querySelector("#cookies_tracking") as HTMLInputElement;

        if (trackingCookies.checked) {
          document.cookie = "allow-tracking=true; samesite=strict; max-age=31536000; path=/";
        }
        cookiesPopup && cookiesPopup.classList.add("hidden");
        document.cookie = "accept-cookies=true; samesite=strict; max-age=31536000; path=/";
      });

    const showMoreBtn = cookiesPopup.querySelector(".show-more");

    showMoreBtn &&
      showMoreBtn.addEventListener("click", (e) => {
        const moreDiv = cookiesPopup.querySelector(".more") as HTMLElement;

        moreDiv && moreDiv.classList.toggle("hidden");
        moreDiv.classList.contains("hidden") ? (showMoreBtn.textContent = "Więcej opcji") : (showMoreBtn.textContent = "Mniej opcji");
      });
  }
};

const onResize = async () => {
  const previousDeviceType = window.deviceType;

  window.deviceType = window.innerWidth > 1366 ? DeviceType.DESKTOP : window.innerWidth > 768 ? DeviceType.TABLET : DeviceType.MOBILE;

  previousDeviceType !== window.deviceType && (await new Menu().init());
};

// @ts-ignore
domReady(main);
