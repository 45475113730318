import { enableScroll, disableScroll, bindEvents } from "./_helpers";
import gsap from "gsap";

export default class Modal {
  protected isOpen: boolean = false;

  constructor(protected readonly modal: HTMLElement, protected readonly openBtnsSelector: string | HTMLElement = ".open-modal", protected readonly closeBtnSelector: string | HTMLElement = ".close-modal") {}

  public async init(): Promise<void> {
    window.addEventListener("click", async (e: Event) => !(await this.clickedOutsideModal(e.target as EventTarget)) && this.closeModal(e));

    this.initEvents();

    gsap.set(this.modal, {
      scale: 0,
      opacity: 0,
    });
  }

  protected async initEvents(): Promise<void> {
    this.openBtnsSelector instanceof HTMLElement
      ? this.openBtnsSelector.addEventListener("click", async ({ target }: Event) => this.openModal(target as EventTarget, this.modal))
      : bindEvents(this.openBtnsSelector, "click", async ({ target }: Event) => this.openModal(target as EventTarget, this.modal));

    this.closeBtnSelector instanceof HTMLElement ? this.closeBtnSelector.addEventListener("click", this.closeModal.bind(this)) : bindEvents(this.closeBtnSelector, "click", this.closeModal.bind(this));
  }

  protected async closeModal({ target }: Event): Promise<void> {
    if (!this.isOpen) return;

    gsap
      .to(this.modal, {
        duration: 0.16,
        scale: 0,
        opacity: 0,
      })
      .then(() => {
        this.modal.classList.add("hidden");
        this.modal.parentElement?.classList.contains("modal-bg") && this.modal.parentElement?.classList.add("hidden");
        this.isOpen = false;
        enableScroll();
      });
  }

  protected async openModal(target: EventTarget, modal: HTMLElement): Promise<void> {
    if (this.isOpen) return;

    modal.classList.remove("hidden");
    modal.parentElement?.classList.contains("modal-bg") && modal.parentElement?.classList.remove("hidden");
    disableScroll();

    gsap
      .to(modal, {
        duration: 0.16,
        scale: 1,
        opacity: 1,
      })
      .then(() => {
        this.isOpen = true;
      });
  }

  protected clickedOutsideModal = async (target: EventTarget) => {
    return target === this.modal || this.modal.contains(target as Node) || this.containsExcludedClass(target as HTMLElement);
  };

  private containsExcludedClass = (target: HTMLElement) => {
    const excludedClasses = ["close-modal", "open-modal", "close-menu", "open-menu", "modal", "buy-modal", "report-modal", "rules-modal", "privacy-policy-modal"];

    return excludedClasses.some((className) => target.classList.contains(className));
  };

  public async getModal(): Promise<HTMLElement> {
    return this.modal;
  }
}
