import { disableScroll, enableScroll, bindEvents } from "./_helpers.js";
import { DeviceType } from "../@types/global";

declare global {
  interface Window {
    deviceType: DeviceType;
  }
}

export default class Menu implements IMenu {
  menu: HTMLElement = document.querySelector(".menu") as HTMLElement;
  menuWrapper: HTMLElement = document.querySelector(".menu-wrapper") as HTMLElement;
  menuLinks: HTMLElement[] = [...document.querySelectorAll(".menu-link")] as HTMLElement[];
  activeSubmenus: HTMLElement[] = [];
  activeSubmenu: HTMLElement | null = null;
  translateClass: string = "xl:translate-x-full";
  isOpen: boolean = false;

  init = async () => {
    bindEvents(".toggle-menu .close-icon", "click", this.closeMenu);
    bindEvents(".toggle-menu .open-icon", "click", this.openMenu);
    // bindEvents('.open-submenu', 'click', this.openSubmenu, this.menu);
    // bindEvents('.close-submenu', 'click', this.closeSubmenu, this.menu);

    bindEvents(".my-menu-item", "click", this.closeMenu, this.menu);
    // bindEvents('.my-menu-item', 'mouseleave', this.closeSubmenuDesktop, this.menu);
  };

  closeMenu = async (): Promise<void> => {
    if (window.deviceType === DeviceType.DESKTOP) return;
    if (!this.isOpen) return;

    enableScroll();
    this.menuWrapper.classList.add(this.translateClass);
    this.menu.classList.remove("active");
    this.menu.querySelector(".toggle-menu .open-icon")?.classList.remove("hidden");
    this.menu.querySelector(".toggle-menu .close-icon")?.classList.add("hidden");
    await this.resetAllSubmenus();
    this.isOpen = false;
  };

  openMenu = async (): Promise<void> => {
    if (window.deviceType === DeviceType.DESKTOP) return;
    if (this.isOpen) return;

    disableScroll();
    this.menuWrapper.classList.remove(this.translateClass);
    this.menu.classList.add("active");
    this.menu.querySelector(".toggle-menu .open-icon")?.classList.add("hidden");
    this.menu.querySelector(".toggle-menu .close-icon")?.classList.remove("hidden");
    this.isOpen = true;
  };

  openSubmenu = async (e: Event): Promise<void> => {
    if (window.deviceType === DeviceType.DESKTOP) return;

    const href: string = (e.target as HTMLElement).getAttribute("href") as string;

    if (typeof href.split("#")[0] === "string" && href.split("#")[0].trim().length === 0) e.preventDefault();

    const slug: string = href.split("#")[1];
    const submenu: HTMLElement = document.querySelector(`[data-submenu="${slug}"]`) as HTMLElement;
    submenu?.classList.add("active");

    this.activeSubmenu = submenu;
    this.activeSubmenus.push(submenu);
  };

  closeSubmenu = async (e: Event): Promise<void> => {
    if (window.deviceType === DeviceType.DESKTOP) return;

    e.preventDefault();

    this.activeSubmenu?.classList.remove("active");
    this.activeSubmenu = null;

    this.activeSubmenus.pop();
    this.activeSubmenu = this.activeSubmenus[this.activeSubmenus.length - 1] ?? null;
  };

  protected async openSubmenuDesktop({ target }: Event): Promise<void> {
    (target as HTMLElement).classList.add("active");
  }

  protected async closeSubmenuDesktop({ target }: Event): Promise<void> {
    const timer = setTimeout(() => {
      (target as HTMLElement).classList.remove("active");
    }, 100);

    (target as HTMLElement).addEventListener("mouseover", () => {
      clearTimeout(timer);
    });
  }

  resetAllSubmenus = async (): Promise<void> => {
    ([...document.querySelectorAll(".header-navigation.active")] as HTMLElement[]).forEach((el) => el.classList.remove("active"));
  };
}
